import { useState } from "react";
import {
  AlignItems,
  Box,
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Color,
  ColorPreset,
  Dialog,
  Field,
  FormFieldStatus,
  Glyph,
  H4,
  Hint,
  HoverEffect,
  Icon,
  Interpose,
  Label,
  P,
  PlainLink,
  Radio,
  Space,
  Text,
  TextArea,
  TypePreset,
  VisuallyHidden,
} from "@gocardless/flux-react";
import { useForm } from "react-hook-form";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { UserLocale, useI18n } from "../i18n";

import { getInputErrorStatus } from "src/utils/inputValidation";
import { useUser } from "src/queries/user";
import { useToastNotification } from "src/hooks/useToastNotification";
import { ZendeskLink } from "src/components/ui/ZendeskLink/ZendeskLink";
import { getFormspreeApiUrl } from "src/utils/formspree";

enum FeedbackType {
  FEEDBACK = "feedback",
  BUG = "bug",
  SUPPORT = "support",
}

interface LeaveFeedbackForm {
  message?: string;
  category: FeedbackType;
}

interface LeaveFeedbackProps {
  children: ({ onClick }: { onClick: () => void }) => JSX.Element;
}

export const LeaveFeedback = ({ children }: LeaveFeedbackProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = () => setIsDialogOpen((previous) => !previous);
  const user = useUser();
  const { i18n } = useLingui();
  const [locale] = useI18n();
  const { triggerSuccessNotification, triggerErrorNotification } =
    useToastNotification();

  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<LeaveFeedbackForm>({
    defaultValues: {
      category: FeedbackType.FEEDBACK,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const watchCategory = watch("category");

  const submitForm = async ({ category, message }: LeaveFeedbackForm) => {
    try {
      const subject: Record<FeedbackType, string> = {
        [FeedbackType.SUPPORT]: `New support request`,
        [FeedbackType.FEEDBACK]: `New feedback submitted`,
        [FeedbackType.BUG]: `New bug report`,
      };

      const response = await fetch(getFormspreeApiUrl("dashboard"), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: user?.id || "",
          email: user?.email || "",
          name: `${user?.given_name} ${user?.family_name}`,
          user_agent: window.navigator.userAgent,
          _subject: subject[category],
          category,
          message,
        }),
      });
      if (response.status !== 200) throw Error("Invalid response");
      triggerSuccessNotification({
        message:
          watchCategory === FeedbackType.FEEDBACK
            ? i18n._(
                t({
                  id: "leave-feedback.feedback-success",
                  message: "Thanks for your feedback",
                })
              )
            : i18n._(
                t({
                  id: "leave-feedback.ticket-success",
                  message:
                    "Thanks. We've opened a ticket and will be in touch.",
                })
              ),
      });
      toggleDialog();
    } catch {
      triggerErrorNotification({
        message: i18n._(
          t({
            id: "leave-feedback.error",
            message: "There was a problem, please try again.",
          })
        ),
      });
    }
  };

  return (
    <>
      {children({ onClick: toggleDialog })}
      <Dialog
        open={isDialogOpen}
        aria-labelledby="leaveFeedbackDialogTitle"
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose: toggleDialog,
        }}
        header={
          <H4
            id="leaveFeedbackDialogTitle"
            data-testid="leaveFeedbackDialogTitle"
            preset={TypePreset.Heading_03}
            color={Color.Greystone_DarkMatter}
          >
            <Trans id="leave-feedback.header">Leave feedback</Trans>
          </H4>
        }
        as="form"
        onSubmit={handleSubmit(submitForm)}
        footer={
          <ButtonGroup
            spacing={1}
            arrangement={["column-center", null, "row-end"]}
          >
            <Button
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
              variant={ButtonVariant.TextOnLight}
              onClick={toggleDialog}
              size={ButtonSize.Sm}
            >
              <Trans id="cancel">Cancel</Trans>
            </Button>
            {watchCategory === FeedbackType.SUPPORT ? (
              <ZendeskLink
                formId={134125}
                data-tracking-label="Leave Feedback"
                layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
                variant={ButtonVariant.PrimaryOnLight}
              >
                <Trans id="submit">Submit</Trans>
              </ZendeskLink>
            ) : (
              <Button
                type="submit"
                layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
                variant={ButtonVariant.PrimaryOnLight}
                size={ButtonSize.Sm}
              >
                <Trans id="submit">Submit</Trans>
              </Button>
            )}
          </ButtonGroup>
        }
      >
        <P preset={TypePreset.Body_02} color={ColorPreset.TextOnLight_02}>
          <Trans id="leave-feedback.choose-options">
            Please choose one of the options below:
          </Trans>
        </P>
        <Space v={2} />
        <Interpose node={<Space v={1.5} />}>
          <Radio
            {...register("category", { required: true })}
            value={FeedbackType.FEEDBACK}
            description={
              <Text
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_02}
              >
                <Trans id="leave-feedback.feedback-description">
                  Feedback helps us improve the product so please provide as
                  much detail on what you&apos;d like to improve and why.
                </Trans>
              </Text>
            }
          >
            <Text
              preset={TypePreset.Heading_02}
              color={ColorPreset.TextOnLight_01}
            >
              <Trans id="leave-feedback.feedback-title">Provide feedback</Trans>
            </Text>
          </Radio>
          <Radio
            {...register("category", { required: true })}
            value={FeedbackType.BUG}
            description={
              <Text
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_02}
              >
                <Trans id="leave-feedback.bug-description">
                  When submitting a bug report, the more information you can
                  provide us, the better.
                </Trans>
              </Text>
            }
          >
            <Text
              preset={TypePreset.Heading_02}
              color={ColorPreset.TextOnLight_01}
            >
              <Trans id="leave-feedback.bug-title">Report a bug</Trans>
            </Text>
          </Radio>
          <Radio
            {...register("category", { required: true })}
            value={FeedbackType.SUPPORT}
            description={
              <Text
                preset={TypePreset.Body_01}
                color={ColorPreset.TextOnLight_02}
              >
                <Trans id="leave-feedback.support-description">
                  Submit a ticket to our support team or you can visit our{" "}
                  <PlainLink
                    href={
                      locale === UserLocale.EN_GB
                        ? "https://hub.gocardless.com/s/knowledge-base"
                        : "https://support.gocardless.com/hc/"
                    }
                    textDecoration="underline"
                    effect={HoverEffect.TextDecoration}
                  >
                    Customer Hub
                  </PlainLink>{" "}
                  for more information.
                </Trans>
              </Text>
            }
          >
            <Text
              preset={TypePreset.Heading_02}
              color={ColorPreset.TextOnLight_01}
            >
              <Trans id="leave-feedback.support-title">
                Help with my account
              </Trans>
            </Text>
          </Radio>
          {watchCategory === FeedbackType.SUPPORT ? null : (
            <Field>
              <VisuallyHidden>
                <Label htmlFor="message">
                  <Trans id="leave-feedback.feedback-label">
                    Feedback message
                  </Trans>
                </Label>
              </VisuallyHidden>
              <TextArea
                {...register("message", {
                  required: true,
                })}
                id="message"
                placeholder={i18n._(
                  t({
                    id: "leave-feedback.feedback-placeholder",
                    message: "Please add feedback here",
                  })
                )}
                status={getInputErrorStatus(!!errors.message)}
              />
              {errors.message ? (
                <Hint status={FormFieldStatus.Danger}>
                  <Trans id="leave-feedback.feedback-required">
                    Please enter a message
                  </Trans>
                </Hint>
              ) : null}
              {watchCategory === FeedbackType.BUG ? (
                <Hint>
                  <Box layout="flex" alignItems={AlignItems.Center}>
                    <Icon name={Glyph.InfoCircle} />
                    <Space h={0.5} layout="inline" />
                    <P
                      preset={TypePreset.Body_01}
                      color={ColorPreset.TextOnLight_01}
                    >
                      <Trans id="leave-feedback.bug-additional-info">
                        Things that are particularly useful to know are: What
                        were you trying to do? What did you expect? And what
                        actually happened?
                      </Trans>
                    </P>
                  </Box>
                </Hint>
              ) : null}
            </Field>
          )}
        </Interpose>
      </Dialog>
    </>
  );
};
