import {
  Aside,
  Box,
  Color,
  H1,
  JustifyContent,
  Separator,
  Sidebar,
  SidebarLayout,
  Space,
  TypeScale,
  XYGrid,
  ZIndex,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
export const DashboardSidebar: React.FC<{
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  title: React.ReactNode;
  footer: React.ReactNode;
}> = ({ children, onClose, open, title, footer }) => (
  <XYGrid templateColumns="max-content auto">
    <Aside>
      <Sidebar
        borderRadius={2}
        open={open}
        closeAction={{
          label: <Trans id="Close">Close</Trans>,
          onClose,
        }}
        layout={SidebarLayout.FloatingRight}
        contentWidth={[null, null, "550px"]}
        css={{
          zIndex: ZIndex.Dialog,
        }}
      >
        <Box
          layout="flex"
          flexDirection="column"
          justifyContent={JustifyContent.SpaceBetween}
          height="100%"
        >
          <Box>
            <Box gutterH={3} spaceAbove={3} width={["100%", null, "80%"]}>
              <H1 size={TypeScale.Size_06}>{title}</H1>
            </Box>
            <Space v={2} />
            <Separator />
            <Space v={2} />
            {children}
          </Box>
          <Box bg={Color.White} css={{ position: "sticky", bottom: 0 }}>
            <Separator />
            <Space v={2} />
            <Box gutterH={3}>{footer}</Box>
            <Space v={2} />
          </Box>
        </Box>
      </Sidebar>
    </Aside>
  </XYGrid>
);
