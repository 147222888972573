import {
  PlainButton,
  HoverEffect,
  AlignItems,
  Box,
  FontWeight,
  Icon,
  Text,
  Space,
  IconButton,
  ButtonVariant,
  Glyph,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { debounce } from "lodash";
import { useForm } from "react-hook-form";
import api from "@gocardless/api/utils/api";
import { getEndpointURL } from "@gocardless/api/dashboard/common/endpoints";
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";

import { Route, routerPush } from "../../common/routing";

import { findIdMatch, isSearchPage, SearchSuggestion } from "./utils";

import { SearchForm } from ".";

import { NO_REDIRECT_TO_404 } from "src/common/api";

interface SearchFormContainerProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
}

const searchSubmit = (data: { search: string }): void => {
  const searchText = data.search?.trim();
  if (searchText.length > 0) {
    routerPush({
      route: Route.Search,
      queryParams: {
        query: searchText,
      },
    });
  }
};

const SearchFormContainer: React.FC<SearchFormContainerProps> = ({
  isOpen,
  setOpen,
  isMobile,
}) => {
  const router = useRouter();
  const queryText = router.query?.query;
  const inSearchPage = isSearchPage(router);

  const [showSuggestions, setShowSuggestions] = useState(false);
  const form = useForm({
    values: {
      search: (queryText as string) || "",
    },
  });
  const searchText = form.watch("search");
  const searchFormContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inSearchPage && queryText) {
      setOpen(true);
    }
  }, [inSearchPage, queryText, setOpen]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        isOpen &&
        searchFormContainerRef.current &&
        !searchFormContainerRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
        if (searchText.trim() === "") {
          setOpen(false);
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [searchText, isOpen, setOpen]);

  const openSearchForm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setOpen(true);
  };

  const [suggestions, setSuggestions] = useState<Array<SearchSuggestion>>();

  const getSearchSuggestions = (value: string) => {
    const match = findIdMatch(value);
    if (match) {
      fetchResource(match);
    } else {
      setSuggestions([]);
    }
  };

  const fetchResource = (match: SearchSuggestion) =>
    api.API.get(getEndpointURL(match.endpoint, { [match.param]: match.id }), {
      headers: { [NO_REDIRECT_TO_404]: "true" },
    })
      .then((resp) => {
        resp.json();
        setSuggestions([match]);
      })
      .catch(() => {
        setSuggestions([]);
      });

  const debouncedSetSearchTerm = debounce((text: string) => {
    getSearchSuggestions(text);
  }, 500);

  return (
    <Box ref={searchFormContainerRef}>
      {!isOpen &&
        (!isMobile ? (
          <PlainButton
            onClick={openSearchForm}
            effect={HoverEffect.TextDecoration}
          >
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Icon name={Glyph.Search} size="12px" />
              <Space h={0.5} />
              <Text weight={FontWeight.SemiBold}>
                <Trans id="Search">Search</Trans>
              </Text>
            </Box>
          </PlainButton>
        ) : (
          <IconButton
            onClick={openSearchForm}
            label={<Trans id="Open search input">Open search input</Trans>}
            icon={Glyph.Search}
            variant={ButtonVariant.TextOnLight}
          />
        ))}
      {isOpen && (
        <SearchForm
          form={form}
          inSearchPage={inSearchPage}
          onSearchValueChange={debouncedSetSearchTerm}
          onSubmit={searchSubmit}
          setOpen={setOpen}
          showSuggestions={showSuggestions}
          suggestions={suggestions}
          onShowSuggestions={() => setShowSuggestions(true)}
        />
      )}
    </Box>
  );
};

export default SearchFormContainer;
