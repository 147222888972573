import {
  Box,
  ColorPreset,
  Form,
  Glyph,
  Icon,
  Input,
  InputGutter,
  PlainButton,
} from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { useEffect, useRef } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

import SearchSuggestions from "./SearchSuggestions";
import { SearchSuggestion } from "./utils";

interface SearchFormProps {
  form: UseFormReturn<{
    search: string;
  }>;
  hasAccessory?: boolean;
  inSearchPage: boolean;
  onSearchValueChange: (value: string) => void;
  onSubmit: (data: { search: string }) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  suggestions?: Array<SearchSuggestion>;
  showSuggestions: boolean;
  onShowSuggestions: () => void;
}

const SearchForm: React.FC<SearchFormProps> = ({
  form,
  hasAccessory = true,
  inSearchPage,
  showSuggestions,
  onSearchValueChange,
  suggestions,
  onSubmit,
  setOpen,
  onShowSuggestions,
}) => {
  const { i18n } = useLingui();
  const {
    handleSubmit,
    watch,
    control,
    formState: { isDirty },
  } = form;

  const searchInputRef = useRef<HTMLInputElement>(null);

  const placeholder = i18n._(t({ id: "Search", message: "Search" }));
  const searchText = watch("search");

  useEffect(() => {
    if (!searchInputRef.current) {
      return;
    }

    // Only focus on it if the user has already typed something
    if (inSearchPage && isDirty) {
      searchInputRef.current.focus();
      return;
    }

    if (!inSearchPage) {
      searchInputRef.current.focus();
    }
  }, [inSearchPage, isDirty]);

  return (
    <Box
      css={{ position: "relative" }}
      width="100%"
      gutterH={[0, 1, 0, 0]}
      maxWidth={["100%", 320, null, null]}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="search"
          render={({ field }) => (
            <Input
              {...field}
              autoComplete="off"
              data-testid="searchInput"
              onClick={(e) => e.stopPropagation()}
              onFocus={onShowSuggestions}
              placeholder={placeholder}
              ref={searchInputRef}
              type="search"
              onChange={(e) => {
                onSearchValueChange(e.target.value);
                field.onChange(e);
              }}
              leftAccessory={
                hasAccessory ? (
                  <Icon
                    name={Glyph.Search}
                    color={ColorPreset.IconOnLight_01}
                    size="14px"
                  />
                ) : null
              }
              rightAccessory={
                hasAccessory ? (
                  <PlainButton
                    css={{ marginRight: "12px", display: "flex" }}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <Icon
                      name={Glyph.Close}
                      color={ColorPreset.IconOnLight_01}
                      size="14px"
                    />
                  </PlainButton>
                ) : null
              }
              gutter={InputGutter.Sm}
            />
          )}
        />
        <SearchSuggestions
          showSuggestions={showSuggestions}
          searchText={searchText}
          suggestions={suggestions}
        />
      </Form>
    </Box>
  );
};

export default SearchForm;
