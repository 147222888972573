export const OrgSwitcherMenuItems = {
  ViewAccounts: "view-accounts",
  ManageTeams: "manage-teams",
  AddAccount: "add-account",
} as const;

export const MultiAccountTrackingContextValues = {
  AccountSwitcher: "Account switcher",
  AccountsList: "Accounts list",
  EditUser: "Edit user",
  InviteUser: "Invite user",
  LinkAccountRequest: "Link account request",
} as const;

export type MultiAccountTrackingContext =
  (typeof MultiAccountTrackingContextValues)[keyof typeof MultiAccountTrackingContextValues];
