import { EventListQueryParamsResourceType } from "@gocardless/api/dashboard/types";

import { Route, getRouteURL } from "src/common/routing";

export interface Notification {
  id: string;
  type: string;
  content: Record<string, string | boolean | number>;
  read: boolean;
  created_at: string;
}

export interface FormattedNotification {
  title: string;
  message?: string;
  read: boolean;
  date: string;
  link: string;
  action?: () => void;
}

export enum NotificationsFilters {
  ALL = "all",
  UNREAD = "unread",
}

export enum NotificationTypes {
  NEW_PAYOUT = "new_payout",
  MONTHLY_PAYOUT_SUMMARY = "monthly_payout_summary",
  CANCELLED_MANDATES_ALERT = "cancelled_mandates_alert",
  CANCELLED_PAYMENTS_ALERT = "cancelled_payments_alert",
  CHARGED_BACK_PAYMENTS_ALERT = "charged_back_payments_alert",
  FAILED_PAYMENTS_ALERT = "failed_payments_alert",
}
export const formatDate = (stringDate: string) => {
  const date = new Date(stringDate);
  return date.toLocaleString("default", {
    day: "numeric",
    month: "short",
  });
};

export const notificationFormatter = (
  notification: Notification
): FormattedNotification | undefined => {
  switch (notification.type) {
    case NotificationTypes.NEW_PAYOUT:
      return {
        title: `Payout received`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `You have received a payment of ${notification.content.amount}`,
        link: getRouteURL({
          route: Route.PayoutTransactions,
          routeParams: {
            id: notification.content.payoutsId as string,
          },
        }),
      };
    case NotificationTypes.MONTHLY_PAYOUT_SUMMARY:
      return {
        title: `Total payouts for ${notification.content.month}`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `You received ${notification.content.payout_count} payouts in ${notification.content.month}`,
        link: getRouteURL({
          route: Route.Payouts,
          queryParams: {
            created_at_gte: (notification.content.period_start as string).split(
              "T"
            )[0],
            created_at_lte: (notification.content.period_end as string).split(
              "T"
            )[0],
          },
        }),
      };
    case NotificationTypes.CANCELLED_MANDATES_ALERT:
      return {
        title: `Cancelled mandates`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `You have received ${
          notification.content.count_cap_exceeded
            ? "100+"
            : notification.content.count
        } cancelled mandates`,
        link: getRouteURL({
          route: Route.Events,
          queryParams: {
            resource_type: EventListQueryParamsResourceType.Mandates,
            action: "cancelled",
            created_at_gte: (notification.content.period_start as string).split(
              "T"
            )[0],
            created_at_lte: (notification.content.period_end as string).split(
              "T"
            )[0],
            limit: "25",
          },
        }),
      };
    case NotificationTypes.CANCELLED_PAYMENTS_ALERT:
      return {
        title: `Cancelled payments`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `${
          notification.content.count_cap_exceeded
            ? "100+"
            : notification.content.count
        } payments have been cancelled`,
        link: getRouteURL({
          route: Route.Events,
          queryParams: {
            resource_type: EventListQueryParamsResourceType.Payments,
            action: "cancelled",
            created_at_gte: (notification.content.period_start as string).split(
              "T"
            )[0],
            created_at_lte: (notification.content.period_end as string).split(
              "T"
            )[0],
            limit: "25",
          },
        }),
      };
    case NotificationTypes.FAILED_PAYMENTS_ALERT:
      return {
        title: `Payment failures`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `${
          notification.content.count_cap_exceeded
            ? "100+"
            : notification.content.count
        } payments have been failed`,
        link: getRouteURL({
          route: Route.Events,
          queryParams: {
            resource_type: EventListQueryParamsResourceType.Payments,
            action: "failed",
            created_at_gte: (notification.content.period_start as string).split(
              "T"
            )[0],
            created_at_lte: (notification.content.period_end as string).split(
              "T"
            )[0],
            limit: "25",
          },
        }),
      };
    case NotificationTypes.CHARGED_BACK_PAYMENTS_ALERT:
      return {
        title: `Charged back payments`,
        read: notification.read,
        date: formatDate(notification.created_at),
        message: `${
          notification.content.count_cap_exceeded
            ? "100+"
            : notification.content.count
        } payments have been charged back`,
        link: getRouteURL({
          route: Route.Events,
          queryParams: {
            resource_type: EventListQueryParamsResourceType.Payments,
            action: "charged_back",
            created_at_gte: (notification.content.period_start as string).split(
              "T"
            )[0],
            created_at_lte: (notification.content.period_end as string).split(
              "T"
            )[0],
            limit: "25",
          },
        }),
      };

    default:
      return undefined;
  }
};
