import {
  AlignItems,
  Box,
  TypePreset,
  Text,
  Color,
  Space,
} from "@gocardless/flux-react";
import Image from "next/image";
import { useRouter } from "next/router";

import PipeLogoGrey from "src/assets/svg/pipe-logo-grey.svg";
import PipeLogoWhite from "src/assets/svg/pipe-logo-white.svg";
import { rawPaths } from "src/common/routing/routes";

export const PipePoweredBy = ({
  variant,
}: {
  variant: "footer" | "navbar";
}) => {
  const router = useRouter();
  const showPipe = router.pathname === rawPaths.Capital;
  if (!showPipe) {
    return null;
  }

  const Logo = variant === "footer" ? PipeLogoGrey : PipeLogoWhite;
  const size =
    variant === "footer"
      ? { width: 46, height: 13 }
      : { width: 40, height: 12 };
  return (
    <Box
      gutterV={0.5}
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
    >
      <Text
        preset={TypePreset.Caption_01}
        color={Color.Greystone_500}
        css={{ color: variant === "footer" ? "#999999" : undefined }}
      >
        Powered by
      </Text>
      <Space h={0.5} layout="inline" />
      <Box>
        <Image src={Logo} {...size} alt="Pipe logo" />
      </Box>
    </Box>
  );
};
