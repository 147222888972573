import {
  H3,
  Box,
  Tabs,
  TabItem,
  TypeScale,
  PlainButton,
  HoverEffect,
  Dialog,
  ColorPreset,
  JustifyContent,
} from "@gocardless/flux-react";

import { NotificationsListDisplay } from "./NotificationsListDisplay";
import { NotificationsFilters, Notification } from "./NotificationFormatter";

import { DashboardSidebar } from "src/common/components/DashboardSidebar";

// TEMP mock data
const mockNotifications = [
  {
    id: "123",
    type: "new_payout",
    content: {
      payoutsId: "PO00001XPC0DBW",
      amount: "40.00 GBP",
    },
    read: false,
    created_at: new Date(Date.now()).toDateString(),
  },
  {
    id: "456",
    type: "monthly_payout_summary",
    content: {
      month: "January",
      period_start: "2025-01-01T00:00:00Z",
      period_end: "2025-02-01T00:00:00Z",
      payout_count: 2,
    },
    read: false,
    created_at: new Date(Date.now()).toDateString(),
  },
  {
    id: "678",
    type: "cancelled_payments_alert",
    content: {
      count_cap_exceeded: true,
      count: 99,
      period_start: "2025-01-01T00:00:00Z",
      period_end: "2025-02-01T00:00:00Z",
    },
    read: true,
    created_at: new Date(Date.now()).toDateString(),
  },
  {
    id: "101",
    type: "failed_payments_alert",
    content: {
      count_cap_exceeded: false,
      count: 99,
      period_start: "2025-01-01T00:00:00Z",
      period_end: "2025-02-01T00:00:00Z",
    },
    read: true,
    created_at: new Date(Date.now()).toDateString(),
  },
] as Notification[];

export const NotificationsSideBar = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <>
    {open ? (
      <DashboardSidebar
        open={open}
        title={<H3>Notifications</H3>}
        footer={
          <PlainButton
            effect={HoverEffect.TextDecoration}
            // onClick={} = This will send a POST request to mark all notifications as read
            // add disabled state if no unread notifications present
          >
            Mark all as read
          </PlainButton>
        }
        onClose={() => onClose()}
      >
        <Box spaceBelow={1} gutterH={2}>
          <Tabs
            initialSelected="unread-tab"
            triggerFontSize={[TypeScale.Size_02]}
          >
            <TabItem id="unread-tab" label="Unread">
              <NotificationsListDisplay
                notifications={[]}
                notificationsType={NotificationsFilters.UNREAD}
              />
            </TabItem>

            <TabItem id="all-tab" label="All notifications">
              <NotificationsListDisplay
                notifications={mockNotifications}
                notificationsType={NotificationsFilters.ALL}
              />
            </TabItem>
          </Tabs>
        </Box>
      </DashboardSidebar>
    ) : null}
  </>
);

export const NotificationsFullScreenDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => (
  <Dialog
    as="div"
    open={open}
    width={["100%"]}
    aria-labelledby="notifications-dialog"
    closeAction={{ label: "Close", onClose }}
    hostGutterH={0}
    hostGutterV={0}
    bodyGutterH={0}
    borderRadius={0}
    height="100%"
    header={
      <Box gutterH={2}>
        <H3>Notifications</H3>
      </Box>
    }
    css={{ display: "block" }}
  >
    <Box height="100%" layout="block" gutterH={2}>
      <Tabs initialSelected="unread-tab" triggerFontSize={[TypeScale.Size_02]}>
        <TabItem id="unread-tab" label="Unread">
          <NotificationsListDisplay
            notifications={[]} //This will be fed by API once be is ready
            notificationsType={NotificationsFilters.UNREAD}
          />
        </TabItem>

        <TabItem id="all-tab" label="All notifications">
          <NotificationsListDisplay
            notifications={mockNotifications} //This will be fed by API once be is ready
            notificationsType={NotificationsFilters.ALL}
          />
        </TabItem>
      </Tabs>
    </Box>
    <Box
      css={{ bottom: 0, position: "fixed" }}
      borderWidth={1}
      borderColor={ColorPreset.BorderOnLight_04}
      width="100%"
    >
      <Box
        gutterH={2}
        gutterV={1}
        layout="flex"
        justifyContent={JustifyContent.Center}
      >
        <PlainButton
          effect={HoverEffect.TextDecoration}
          // onClick={} = This will send a POST request to mark all notifications as read
          // add disabled state if no unread notifications present
        >
          Mark all as read
        </PlainButton>
      </Box>
    </Box>
  </Dialog>
);
