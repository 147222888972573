import Image from "next/image";
import { OrganisationDetailsResource } from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";

import { menuItemStyle } from "../OrganisationSwitcher.style";

import childOrgIllustration from "src/assets/svg/child-org.svg";
import parentOrgIllustration from "src/assets/svg/parent-org.svg";
import {
  useOrgSwitcherData,
  useSwitchOrganisation,
} from "src/libraries/organisation/multi-accounts";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { MultiAccountTrackingContextValues } from "src/components/routes/Accounts/constants";

export const OrganisationMenuItem: React.FC<
  OrganisationDetailsResource & { useCustomHoverStyle?: boolean }
> = ({ id, nickname, name, useCustomHoverStyle = false }) => {
  const { loggedInOrganisation, parentOrganisation } = useOrgSwitcherData();
  const organisationId = id ?? undefined;
  const { submit } = useSwitchOrganisation({ organisationId });

  const { sendEvent } = useSegment();
  const { theme } = useTheme();

  const isParentOrg = parentOrganisation?.id === id;

  const handleClick = () => {
    const eventName = isParentOrg
      ? TrackingEvent.MULTI_ACCOUNT_PARENT_ACCOUNT_OPTION_CLICKED
      : TrackingEvent.MULTI_ACCOUNT_CHILD_ACCOUNT_OPTION_CLICKED;

    sendEvent(eventName, {
      from_organisation_id: loggedInOrganisation.id,
      to_organisation_id: organisationId,
      ctx: MultiAccountTrackingContextValues.AccountSwitcher,
    });

    submit(organisationId);
  };

  return (
    <Menu.Button
      css={menuItemStyle(theme, { useCustomHoverStyle })}
      onClick={handleClick}
    >
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Image
          src={isParentOrg ? parentOrgIllustration : childOrgIllustration}
          alt=""
          height={28}
          width={28}
        />
        <Space h={1} layout="inline" />
        <Box>
          <Text layout="block" size={2} weight={FontWeight.SemiBold}>
            {nickname ?? name}
          </Text>
          <Text color={ColorPreset.TextOnLight_03} size={1}>
            {id}
          </Text>
        </Box>
      </Box>
    </Menu.Button>
  );
};
