import { ComponentProps, useId } from "react";
import Image from "next/image";
import { Trans } from "@lingui/macro";
import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  Glyph,
  Interpose,
  JustifyContent,
  Menu,
  MenuDensity,
  MenuRole,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";
import { OrganisationDetailsResource } from "@gocardless/api/dashboard/types";

import { switcherMenuStyle } from "./OrganisationSwitcher.style";
import { OrganisationSwitcher } from "./OrganisationSwitcher";
import { OrganisationMenuItem } from "./menu-items/OrganisationMenuItem";
import { GeneralMenuItem } from "./menu-items/GeneralMenuItem";
import { LoggedInTag } from "./tags/LoggedInTag";

import { useOrgSwitcherData } from "src/libraries/organisation/multi-accounts";
import { useUserHasWritePermissions } from "src/common/hooks/user-permissions/useUserPermissions";
import { OrgSwitcherMenuItems } from "src/components/routes/Accounts/constants";
import childOrgIllustration from "src/assets/svg/child-org.svg";
import parentOrgIllustration from "src/assets/svg/parent-org.svg";
import { Route } from "src/components/routing";

export interface MenuActionProps {
  id: (typeof OrgSwitcherMenuItems)[keyof typeof OrgSwitcherMenuItems];
  icon: Glyph;
  title: React.ReactNode;
  route: Route | undefined;
  disabled?: boolean;
}

const useMenuActions = () => {
  const { hasWritePermissions } = useUserHasWritePermissions();

  const menuActions: MenuActionProps[] = [
    {
      id: OrgSwitcherMenuItems.ViewAccounts,
      icon: Glyph.Account,
      title: <Trans>View all accounts</Trans>,
      route: Route.Accounts,
    },
    ...((hasWritePermissions
      ? [
          {
            id: OrgSwitcherMenuItems.ManageTeams,
            icon: Glyph.User,
            title: <Trans>Manage teams</Trans>,
            route: Route.Team,
          },
          {
            id: OrgSwitcherMenuItems.AddAccount,
            icon: Glyph.Add,
            title: <Trans>Add account</Trans>,
            route: undefined,
          },
        ]
      : []) as MenuActionProps[]),
  ];

  return menuActions;
};

interface OrganisationSwitcherMenuProps
  extends ComponentProps<typeof OrganisationSwitcher> {
  currentOrg?: OrganisationDetailsResource;
  organisations: OrganisationDetailsResource[];
  onAddAccountClick?: () => void;
}

const OrganisationMenuGroup = ({
  organisations,
  useCustomHoverStyle = false,
}: {
  organisations: OrganisationDetailsResource[];
  useCustomHoverStyle?: boolean;
}) => (
  <>
    <Menu.Group description={null} title={null}>
      <Interpose node={<Space v={1} />}>
        {organisations.map(({ id, nickname, name }) => {
          if (!id) return null;

          return (
            <OrganisationMenuItem
              key={id}
              id={id}
              nickname={nickname}
              name={name}
              useCustomHoverStyle={useCustomHoverStyle}
            />
          );
        })}
      </Interpose>
    </Menu.Group>
    <Menu.Separator />
  </>
);

export const OrganisationSwitcherMenu: React.FC<
  OrganisationSwitcherMenuProps
> = ({
  currentOrg,
  organisations,
  onAddAccountClick,
  useCustomHoverStyle = false,
}) => {
  const { parentOrganisation } = useOrgSwitcherData();
  const menuActions = useMenuActions();

  const menuId = useId();
  const { theme } = useTheme();

  const isParentOrg = parentOrganisation?.id === currentOrg?.id;

  return (
    <Menu.Root
      id={menuId}
      role={MenuRole.Menu}
      density={MenuDensity.Compact}
      css={switcherMenuStyle(theme)}
    >
      <Menu.Group title={null} description={null}>
        <Box
          layout="flex"
          alignItems={AlignItems.Center}
          justifyContent={JustifyContent.SpaceBetween}
        >
          <Box layout="flex" alignItems={AlignItems.Center}>
            <Image
              src={isParentOrg ? parentOrgIllustration : childOrgIllustration}
              alt=""
              width={28}
              height={28}
            />
            <Space layout="inline" h={1} />
            <Box>
              <Text
                color={Color.Ultraviolet_1100}
                layout="block"
                size={2}
                weight={FontWeight.SemiBold}
              >
                {currentOrg?.nickname ?? currentOrg?.name}
              </Text>
              <Text color={Color.Ultraviolet_700} size={1}>
                {currentOrg?.id}
              </Text>
            </Box>
          </Box>
          <Space layout="inline" h={1} />
          <LoggedInTag />
        </Box>
      </Menu.Group>

      <Menu.Separator />

      {organisations.length > 0 ? (
        <OrganisationMenuGroup organisations={organisations} />
      ) : null}

      {menuActions.map(({ disabled, ...props }) => (
        <GeneralMenuItem
          key={props.id}
          actionProps={props}
          disabled={disabled}
          useCustomHoverStyle={useCustomHoverStyle}
          onClick={
            props.id === OrgSwitcherMenuItems.AddAccount
              ? onAddAccountClick
              : undefined
          }
        />
      ))}
    </Menu.Root>
  );
};
