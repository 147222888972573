import { Trans } from "@lingui/macro";
import {
  Button,
  ButtonGroup,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  ColorPreset,
  Dialog,
  H1,
  TypePreset,
  P,
  Space,
} from "@gocardless/flux-react";

export interface UpcomingFeatureDialogProps {
  open: boolean;
  onClose: () => void;
}

export const UpcomingFeatureDialog: React.FC<UpcomingFeatureDialogProps> = ({
  open,
  onClose,
}) => (
  <Dialog
    open={open}
    aria-labelledby="upcomingFeatureDialogTitle"
    closeAction={{ label: <Trans id="Close">Close</Trans>, onClose: onClose }}
    header={
      <H1
        color={ColorPreset.TextOnLight_01}
        preset={TypePreset.Heading_02}
        id="upcomingFeatureDialogTitle"
      >
        <Trans>Feature coming soon!</Trans>
      </H1>
    }
    footer={
      <ButtonGroup arrangement={["column-center", "row-end"]}>
        <Button
          layout={[ButtonLayout.Full, ButtonLayout.Inline]}
          variant={ButtonVariant.PrimaryOnLight}
          size={[ButtonSize.Lg, ButtonSize.Sm]}
          onClick={onClose}
        >
          <Trans id="Close">Close</Trans>
        </Button>
      </ButtonGroup>
    }
  >
    <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
      <Trans>
        Adding additional accounts directly through the dashboard is not
        currently supported.
      </Trans>
    </P>
    <Space v={1.5} />
    <P preset={TypePreset.Body_01} color={ColorPreset.TextOnLight_02}>
      <Trans>
        If you wish to add and link an additional account, please reach out to
        your GoCardless representative. They will be able to guide you through
        the process and address any questions you may have.
      </Trans>
    </P>
  </Dialog>
);
