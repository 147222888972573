import {
  Box,
  JustifyContent,
  H1,
  TypePreset,
  P,
  Separator,
  ColorPreset,
  Interpose,
  Space,
  PlainLink,
  AlignItems,
  Icon,
  Glyph,
  Color,
  TextAlign,
  HoverEffect,
  TextWrap,
} from "@gocardless/flux-react";
import Image from "next/image";

import {
  FormattedNotification,
  NotificationsFilters,
  notificationFormatter,
  Notification,
} from "./NotificationFormatter";

import PaperPlanImage from "src/assets/svg/paperplane.svg";

export const NotificationsListDisplay = ({
  notifications,
  notificationsType,
}: {
  notifications: Notification[];
  notificationsType: NotificationsFilters;
}) => {
  if (notifications.length === 0) {
    return (
      <Box
        layout="flex"
        alignItems={AlignItems.Center}
        flexDirection="column"
        spaceAbove={2}
      >
        <Box>
          <Image src={PaperPlanImage} alt="" width={80} height={80} />
        </Box>

        <P>
          {notificationsType === NotificationsFilters.UNREAD
            ? "You don’t have any unread notifications."
            : "You don’t have any notifications"}
        </P>
      </Box>
    );
  }

  return (
    <Box width="100%">
      <Interpose node={<Space v={1.5} />}>
        {notifications.map((notification) => {
          const formattedNotification = notificationFormatter(
            notification
          ) as FormattedNotification;
          return (
            <Box key={notification.id}>
              <Box layout="flex" flexDirection="row" spaceBelow={2}>
                <PlainLink
                  href={formattedNotification.link}
                  onClick={formattedNotification.action}
                  css={{ width: "100%" }}
                  effect={HoverEffect.TextDecoration}
                >
                  <H1 preset={TypePreset.Heading_01}>
                    {formattedNotification.title}
                  </H1>
                  <P color={ColorPreset.TextOnLight_03} size={1}>
                    {formattedNotification.message}
                  </P>
                </PlainLink>
                <Box
                  justifyContent={JustifyContent.FlexEnd}
                  alignItems={AlignItems.Center}
                  layout="flex"
                  flexDirection="row"
                >
                  <Box spaceAfter={0.5}>
                    <P
                      color={ColorPreset.TextOnLight_03}
                      size={1}
                      textAlign={TextAlign.Center}
                      textWrap={TextWrap.NoWrap}
                    >
                      {formattedNotification.date}
                    </P>
                  </Box>
                  <Icon
                    name={Glyph.Disc}
                    color={
                      !formattedNotification.read
                        ? Color.Ultraviolet_600
                        : Color.Transparent
                    }
                    size="10px"
                  />
                </Box>
              </Box>
              <Separator color={ColorPreset.BorderOnLight_04} />
            </Box>
          );
        })}
      </Interpose>
    </Box>
  );
};
