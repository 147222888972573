import {
  AlignItems,
  Box,
  FontWeight,
  Icon,
  Menu,
  Space,
  Text,
  useTheme,
} from "@gocardless/flux-react";

import { menuItemStyle } from "../OrganisationSwitcher.style";
import { MenuActionProps } from "../OrganisationSwitcherMenu";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { MultiAccountTrackingContextValues } from "src/components/routes/Accounts/constants";
import { LinkBuilder } from "src/components/routing";

interface GeneralMenuItemProps {
  actionProps: MenuActionProps;
  disabled?: boolean;
  useCustomHoverStyle?: boolean;
  onClick?: () => void;
}

const getTrackingEvent = (menuItemId: MenuActionProps["id"]) => {
  switch (menuItemId) {
    case "view-accounts":
      return TrackingEvent.MULTI_ACCOUNT_VIEW_ALL_ACCOUNTS_CTA_CLICKED;
    case "manage-teams":
      return TrackingEvent.MULTI_ACCOUNT_MANAGE_TEAMS_CTA_CLICKED;
    case "add-account":
      return TrackingEvent.MULTI_ACCOUNT_ADD_ACCOUNT_CTA_CLICKED;
  }
};

export const GeneralMenuItem: React.FC<GeneralMenuItemProps> = ({
  actionProps: { id, icon, title, route },
  useCustomHoverStyle = false,
  onClick,
}) => {
  const { theme } = useTheme();
  const { sendEvent } = useSegment();

  const handleClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    const eventName = getTrackingEvent(id);

    sendEvent(eventName, {
      ctx: MultiAccountTrackingContextValues.AccountSwitcher,
    });
  };

  const menuItemContent = (
    <Box layout="flex" alignItems={AlignItems.Center} gutterH={0.5}>
      <Icon size="12px" name={icon} />
      <Space h={1} layout="inline" />
      <Text weight={FontWeight.SemiBold}>{title}</Text>
    </Box>
  );

  if (!route) {
    return (
      <Menu.Button
        onClick={handleClick}
        css={menuItemStyle(theme, { useCustomHoverStyle })}
      >
        {menuItemContent}
      </Menu.Button>
    );
  }

  return (
    <LinkBuilder route={route} onClick={handleClick}>
      {(result) => (
        <Menu.Link
          {...result}
          css={menuItemStyle(theme, { useCustomHoverStyle })}
        >
          {menuItemContent}
        </Menu.Link>
      )}
    </LinkBuilder>
  );
};
