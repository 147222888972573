import {
  AlignItems,
  Box,
  Color,
  CSSRulesFunction,
  PlainLink,
  ResponsiveValue,
  useTheme,
} from "@gocardless/flux-react";
import Image from "next/image";

import LightLogo from "../assets/svg/gocardless-wordmark-rebrand-light.svg";
import DarkLogo from "../assets/svg/gocardless-wordmark-rebrand-dark.svg";

import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/technical-integrations/segment/useSegment";

export interface GoCardlessLogoProps {
  variant: "light" | "dark";
  size: ResponsiveValue<"xs" | "sm" | "md" | "lg">;
  logoURL?: null | string;
}
const sizes = {
  xs: ["120px", "auto"],
  sm: ["120px", "auto"],
  md: ["150px", "auto"],
  lg: ["150px", "auto"],
};

const sizeStyle: CSSRulesFunction<Pick<GoCardlessLogoProps, "size">> = (
  theme,
  { size }
) =>
  theme.responsive(size, (sizeValue) => {
    const [width, height] = sizes[sizeValue];
    return { width, height };
  });

const colorStyle: CSSRulesFunction<Pick<GoCardlessLogoProps, "variant">> = (
  theme,
  { variant }
) => {
  const fill =
    variant === "light"
      ? theme.color(Color.White)
      : theme.color(Color.Greystone_DarkMatter);
  return { path: { fill } };
};

const LogoComponent: React.FC<GoCardlessLogoProps> = (props) => {
  const { theme } = useTheme();
  const { variant, size } = props;

  const { width, height } =
    !Array.isArray(size) && ["md", "lg"].includes(size)
      ? { width: 150, height: 22 }
      : { width: 120, height: 18 };

  return (
    <Box
      layout="flex"
      flexDirection="row"
      alignItems={AlignItems.Center}
      css={sizeStyle(theme, { size })}
    >
      <Image
        src={props.variant === "light" ? LightLogo : DarkLogo}
        alt="GoCardless"
        width={width}
        height={height}
        css={colorStyle(theme, { variant })}
      />
    </Box>
  );
};

const GoCardlessLogo: React.FC<GoCardlessLogoProps> = (props) => {
  const { sendEvent } = useSegment();

  return props.logoURL ? (
    <PlainLink
      href={props.logoURL}
      onClick={() => sendEvent(TrackingEvent.LOGO_CLICKED)}
    >
      <LogoComponent {...props} />
    </PlainLink>
  ) : (
    <LogoComponent {...props} />
  );
};
export default GoCardlessLogo;
