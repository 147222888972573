import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { Endpoint } from "@gocardless/api/dashboard/common/endpoints";
import { NextRouter } from "next/router";

import { Route } from "src/common/routing";

export enum SearchResourceType {
  Payment = "payment",
  Customer = "customer",
  Creditor = "creditor",
  CustomerBankAccount = "customer_bank_account",
  Mandate = "mandate",
  Payout = "payout",
  Refund = "refund",
  Subscription = "subscription",
  SubscriptionTemplate = "subscription template",
  User = "user",
}

export interface SearchSuggestion {
  id: string;
  route: Route;
  type: SearchResourceType;
  endpoint: Endpoint;
  param: string;
}

interface IdMatcher {
  route: string;
  type: string;
  regex: RegExp;
  endpoint: Endpoint;
  param: string;
}

const ID_MATCHES: Array<IdMatcher> = [
  {
    route: Route.Payment,
    type: SearchResourceType.Payment,
    regex: /^(PM)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.PaymentSelf,
    param: "paymentId",
  },
  {
    route: Route.Customer,
    type: SearchResourceType.Customer,
    regex: /^(CU)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.CustomerSelf,
    param: "customerId",
  },
  {
    route: Route.CreditorDetails,
    type: SearchResourceType.Creditor,
    regex: /^(CR)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.CreditorSelf,
    param: "creditorId",
  },
  {
    route: Route.CustomerBankAccount,
    type: SearchResourceType.CustomerBankAccount,
    regex: /^(BA)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.CustomerBankAccountSelf,
    param: "customerBankAccountId",
  },
  {
    route: Route.Mandate,
    type: SearchResourceType.Mandate,
    regex: /^(MD)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.MandateSelf,
    param: "mandateId",
  },
  {
    route: Route.Payout,
    type: SearchResourceType.Payout,
    regex: /^(PO)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.PayoutSelf,
    param: "payoutId",
  },
  {
    route: Route.Refund,
    type: SearchResourceType.Refund,
    regex: /^(RF)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.RefundSelf,
    param: "refundId",
  },
  {
    route: Route.Subscription,
    type: SearchResourceType.Subscription,
    regex: /^(SB)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.SubscriptionSelf,
    param: "subscriptionId",
  },
  {
    route: Route.SubscriptionTemplate,
    type: SearchResourceType.SubscriptionTemplate,
    regex: /^(PL)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.PlanSelf,
    param: "planId",
  },
  {
    route: Route.TeamUserEdit,
    type: SearchResourceType.User,
    regex: /^(US)[0-9A-Z]{12,}$/i,
    endpoint: Endpoint.UserSelf,
    param: "userId",
  },
];

export const searchResourceTypeToText = (): Record<
  SearchResourceType,
  string
> => ({
  [SearchResourceType.Payment]: i18n._(
    t({ id: "search.go-to-payment", message: "Go to payment" })
  ),
  [SearchResourceType.Customer]: i18n._(
    t({ id: "search.go-to-customer", message: "Go to customer" })
  ),
  [SearchResourceType.Creditor]: i18n._(
    t({ id: "search.go-to-creditor", message: "Go to creditor" })
  ),
  [SearchResourceType.CustomerBankAccount]: i18n._(
    t({
      id: "search.go-to-customer_bank_account",
      message: "Go to customer bank account",
    })
  ),
  [SearchResourceType.Mandate]: i18n._(
    t({ id: "search.go-to-mandate", message: "Go to mandate" })
  ),
  [SearchResourceType.Payout]: i18n._(
    t({ id: "search.go-to-payout", message: "Go to payout" })
  ),
  [SearchResourceType.Refund]: i18n._(
    t({ id: "search.go-to-refund", message: "Go to refund" })
  ),
  [SearchResourceType.Subscription]: i18n._(
    t({ id: "search.go-to-subscription", message: "Go to subscription" })
  ),
  [SearchResourceType.User]: i18n._(
    t({ id: "search.go-to-user", message: "Go to user" })
  ),
  [SearchResourceType.SubscriptionTemplate]: i18n._(
    t({
      id: "search.go-to-subscription-template",
      message: "Go to subscription template",
    })
  ),
});

export const findIdMatch = (value: string): SearchSuggestion | undefined => {
  const match = ID_MATCHES.find((x) => value.match(x.regex));

  if (!match) return undefined;

  const foundMatch = match as unknown as SearchSuggestion;
  return {
    id: value,
    type: foundMatch.type,
    route: foundMatch.route,
    endpoint: foundMatch.endpoint,
    param: foundMatch.param,
  };
};

export const isSearchPage = (router: NextRouter) =>
  router?.pathname?.includes("search");
