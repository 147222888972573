import {
  Box,
  ColorScheme,
  Tag,
  TagColor,
  TagSize,
  TagVariant,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";

export const LoggedInTag = () => (
  <Box>
    <Tag
      variant={TagVariant.Tinted}
      size={TagSize.Md}
      colorScheme={ColorScheme.OnLight}
      color={TagColor.Info}
    >
      <Text size={1}>
        <Trans>Current</Trans>
      </Text>
    </Tag>
  </Box>
);
