import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { UsersScope } from "@gocardless/api/dashboard/types";
import { useEffect } from "react";

export const useUserPermissions = ({
  onSuccess,
}: {
  onSuccess?: () => void;
} = {}) => {
  const { data, error, isLoading } = useUserShowSelf();

  useEffect(() => {
    if (data && !isLoading) {
      onSuccess?.();
    }
  }, [isLoading, onSuccess, data]);

  return {
    ...getScope(data?.users?.scope),
    error,
    loading: isLoading,
  };
};

const getScope = (scope?: UsersScope) => {
  switch (scope) {
    case UsersScope.Admin:
      return { isAdmin: true };
    case UsersScope.ReadWrite:
      return { isReadWrite: true };
    case UsersScope.ReadOnly:
      return { isRead: true };
    default:
      return { isRead: true };
  }
};

export const useUserHasWritePermissions = () => {
  const { isAdmin, isReadWrite, error, loading } = useUserPermissions();

  return {
    hasWritePermissions: !!(isAdmin || isReadWrite),
    error,
    loading,
  };
};
