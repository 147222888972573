import {
  AlignItems,
  Box,
  Color,
  FontWeight,
  HoverEffect,
  PlainLink,
  Space,
  Text,
  useTheme,
  Visibility,
} from "@gocardless/flux-react";
import Image from "next/image";
import { Trans } from "@lingui/macro";

import { LinkBuilder, Route } from "../routing";

import ChooseReferralRewardReferButtonIcon from "src/assets/svg/choose-referral-reward-refer-button-icon.svg";
import { useSegment } from "src/technical-integrations/segment/useSegment";
import { TrackingEvent } from "src/common/trackingEvents";
import { useReferralAmount } from "src/components/routes/Refer/hooks/useReferralAmount";
import { ReferralsSource } from "src/common/trackingEventAttributes";

const ChooseReferralRewardReferButton = () => {
  const { sendEvent } = useSegment();
  const referralAmount = useReferralAmount();
  const { theme } = useTheme();

  return (
    <>
      <Visibility visible={["none", null, null, "block"]}>
        <LinkBuilder
          route={Route.Refer}
          onClick={() => {
            sendEvent(TrackingEvent.DASHBOARD_REFER_CTA_CLICKED, {
              source: ReferralsSource.REFER_BUTTON,
            });
          }}
        >
          {(result) => (
            <PlainLink effect={HoverEffect.TextDecoration} {...result}>
              <Box
                layout="flex"
                flexDirection="row"
                alignItems={AlignItems.Center}
              >
                <Image
                  src={ChooseReferralRewardReferButtonIcon}
                  alt=""
                  width={12}
                  height={12}
                />
                <Space layout="inline" h={0.5} />
                <Text
                  weight={FontWeight.SemiBold}
                  size={3}
                  color={Color.Ultraviolet_700}
                >
                  <Trans id="refer.button-label">
                    Refer & get {referralAmount.incentive}
                  </Trans>
                </Text>
              </Box>
            </PlainLink>
          )}
        </LinkBuilder>
      </Visibility>
      <Visibility visible={["block", null, null, "none"]}>
        <LinkBuilder
          route={Route.Refer}
          onClick={() => {
            sendEvent(TrackingEvent.DASHBOARD_REFER_CTA_CLICKED, {
              source: ReferralsSource.REFER_BUTTON,
            });
          }}
        >
          {(result) => (
            <PlainLink {...result}>
              <Box
                layout="flex"
                alignItems={AlignItems.Center}
                borderRadius={3}
                gutterH={1}
                gutterV={1}
                css={{
                  ":hover": {
                    backgroundColor: theme.color(Color.Greystone_300),
                  },
                }}
              >
                <Image
                  src={ChooseReferralRewardReferButtonIcon}
                  alt=""
                  width={14}
                  height={14}
                />
              </Box>
            </PlainLink>
          )}
        </LinkBuilder>
      </Visibility>
    </>
  );
};

export default ChooseReferralRewardReferButton;
